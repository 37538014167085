import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/node/work/src/templates/post.template.tsx";
export const clickHandler = e => {
  location.href = "/shell";
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MediumButton = makeShortcode("MediumButton");
const layoutProps = {
  clickHandler,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Recently I rediscovered `}<a parentName="p" {...{
        "href": "https://github.com/copy/v86"
      }}>{`v86`}</a>{` and after browsing through the examples, I thought
it would be cool to "self host" my own browser shell / OS. So I gave it a try
and am happy to show TAOA Shell! [`}<a parentName="p" {...{
        "href": "https://github.com/cmrfrd/taoa.shell"
      }}>{`source`}</a>{`]`}</p>

    <div onClick={clickHandler} style={{
      "display": "block",
      "margin": "0px auto 0px"
    }}>
     <MediumButton text="To TAOA Shell" mdxType="MediumButton" />
    </div>
    <p>{`With the amazingly simple interface of v86, anyone can have an extremely
accessible OS in their browser for near zero cost!`}</p>
    <p>{`This is possible because v86 emulates an x86 computer with WebAssembly and
Javascript. Since these are just static assets, they can run in almost any
browser and be hosted on a number of platforms, such GitHub pages, Netlify, and
Digital Ocean, for free!`}</p>
    <p>{`Now unfortunately the v86 emulator is slow compared to bare metal (sometimes a
10-100x slowdown). But it's fast enough and usable for a lot of tasks!`}</p>
    <p>{`Now not only is this cool, but there is a personal use case I care about, which
is the ability to self host my side projects that `}<strong parentName="p">{`aren't`}</strong>{` naturally web based.`}</p>
    <p>{`For example: my last weekend side project was about `}<a parentName="p" {...{
        "href": "file:///posts/Writing-a-Weaver-solver"
      }}>{`solving weaver`}</a>{`. Now if
people want to use the solver script, they'd have to copy the code, download the
weaver graph, and run it. But now, with the click of a link, they can just try
it in TAOA Shell to see it in action.`}</p>
    <p>{`I didn't have to rewrite it into Javascript or host a server somewhere. Instead
I just dump the code into TAOA Shell, and ✨like magic✨ it's on the internet!`}</p>
    <p>{`There's also the added benefit of this generalizing beyond python scripts to
almost anything that can run in Linux!`}</p>
    <p>{`Thanks for reading! 🙋🏼`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      